import { getAtomicRequestFileds } from 'public/src/services/goodsItemInfo/utils.js'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import { getSiteMallInfo } from 'public/src/pages/common/getSiteMallInfo'
import { getDetailRecPreTreat } from 'public/src/pages/goods_detail_v3/utils/productDetail.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import schttp from 'public/src/services/schttp'
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils'
import {
  transformImg,
  template,
  htmlDecode,
  getCookie
} from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'
import { getDetailRecommendCardV3ReqAbtInfoCCCParams, getDetailRecommendAtomFields, getNotEstimatedPriceConfig } from 'public/src/pages/goods_detail_v2/utils/productDetail.js'
import { SMessage } from '@shein-aidc/sui-message'
/**
 * 格式化商品项数据支持组合购功能
 */
export const getFormatCommonCartProductItem = (item = {}) => {
  const {
    sale_attr_list = {},
    mainSaleAttr = {},
    relatedColor = [],
    goods_id,
    promotion_group
  } = item
  const _goodsIdStr = String(goods_id)
  const { skc_sale_attr = [] } = sale_attr_list
  // 主销售属性名称
  const mainAttrName = mainSaleAttr.main_attribute_value_name || ''
  let mainAttrImg = ''
  // 主销售属性，是色块需要取取色块图
  const _isColor = mainSaleAttr.main_sale_attr_show_mode === 2
  // 存在色块且skc不止1个，取出色块图
  if (_isColor && relatedColor.length) {
    const mainColorItem =
      relatedColor.find(d => String(d.goods_id) === _goodsIdStr) || {}
    if (mainColorItem.goods_img) {
      mainAttrImg = transformImg({ img: mainColorItem.goods_color_image })
    }
  }
  // 单skc单sku
  const isOneSku =
    skc_sale_attr.length === 1 && skc_sale_attr[0].attr_value_list?.length === 1

  // 无销售属性
  const isNotSku = !skc_sale_attr.length
  /**
   * 不存在sku有多个skc存在的情况
   */
  const isOnlySkc = isNotSku && relatedColor.length > 1
  // 多个次级销售属性
  const isMultiAttrs = skc_sale_attr.length > 1

  /**
   * 销售属性名称
   */
  const skuTypeName = skc_sale_attr
    .map(d => d.attr_name?.trim?.())
    .filter(d => d)
    .join('/')

  const skuCode = ''
  const skuName = ''

  const isSoldOut = item.stock == 0 || item.is_on_sale == 0
  /**
   * 只有选中的sku售罄
   */
  const isOnlySkuSoldOut = false
  /**
   * 优惠券分组
   * 为1，则为优惠券商品 为空或为0： 则为折扣商品
   */
  const isPromotionGroup = promotion_group == '1'

  /**
   * 勾选状态
   */
  const selectStatus = false
  /**
   * 是否为主商品
   */
  const isMainGoods = false

  /**
   * 是否spu售罄，有整个面板置灰的专属样式，如果存在多色块需要异步获取，否则直接读取当前skc售罄状态
   */
  const isSpuSoldOut = relatedColor.length > 1 ? false : isSoldOut

  return {
    ...item,
    _cartInfo: {
      isMainGoods,
      isSoldOut,
      isOnlySkuSoldOut,
      isSpuSoldOut,
      isOneSku,
      isNotSku,
      isOnlySkc,
      isMultiAttrs,
      // /**
      //  * 是否需要选中sku
      //  */
      // isNeedSelectSku: !isNotSku,
      /**
       * 勾选状态
       */
      selectStatus,
      /**
       * 外部勾选状态，排序用
       */
      _selectStatusOutSide: false,
      /**
       * 未选中sku警告状态
       */
      warnStatus: false,
      /**
       * 选中的skuCode
       */
      skuCode,
      mainAttrName,
      mainAttrImg,
      skuName,
      skuTypeName,
      isPromotionGroup,
      /**
       * 快车弹窗回显Sku用
       */
      selectedAttrIndex: {}
    }
  }
}

export const getFormatComboBuyProductList = (list = []) => {
  if (!list.length) return []
  const _newList = list.map(getFormatCommonCartProductItem)
  _newList[0]._cartInfo.isMainGoods = true
  return _newList
}

/**
 * 计算套装价
 */
export const getBundlePrice = async ({ list, onlySkc }) => {
  const data = {
    params: (list || []).map(d => ({
      mall_code: d.mall_code,
      goods_id: d.goods_id,
      skc: d.skc,
      sku: onlySkc ? undefined : d?._cartInfo?.skuCode || undefined // 可选参数
    }))
  }
  const result = await schttp({
    url: '/api/productInfo/getBundlePrice/get',
    method: 'POST',
    data
  })
  return result?.info || {}
}

export const getCalcBundlePrice = (
  bundlePrice = {},
  language = {},
  config = {},
) => {
  const { isPaidUser, isHitComplianceMode } = config
  const is_vip = isPaidUser ? 1 : 0
  const {
    /**
     * 券后价
     */
    bundleCouponPrices = [],
    /**
     * 标签价
     */
    bundleDisplayPrices = [],
    /**
     * 套装差价
     */
    bundleDiscountPrice = {},
    /**
     * 套装原价
     */
    bundleRetailPrice = {},
    /**
     * 	套装售价
     */
    bundleSalePrice = {},
    /**
     * AB价价差
     * 可能为 null，如果为 null 表示套装里面是不含有 ab 价
     */
    bundleAbDiscountPrice = {},
    /**
     * 套装差价 - 不含AB差价
     * 若无AB价则等于套装差价
     */
    bundleDiscountPriceWithoutAb = {},
    unit_discount = 0
  } = bundlePrice

  const res = {
    // amount: 0,
    amountWithSymbol: '0.00',
    /**
     * 折扣率
     */
    discount: 0,
    /**
     * 价差
     */
    // discountAmount: 0,
    discountAmountWithSymbol: '',
    /**
     * 原价
     */
    // retailAmout: bundleRetailPrice?.amount || 0,
    retailAmountWithSymbol: bundleRetailPrice?.amountWithSymbol || '',
    /**
     * 最优惠券信息
     */
    optimalCoupon: null,
    couponList: [],

    /**
     * 展示的Activity价差
     */
    activityAmountWithSymbol: '',
    /**
     * 倒计时结束时间
     */
    couponEndDate: 0
  }

  if (Object.keys(bundlePrice).length === 0) return res

  /**
   * 付费会员取的优先级“付费会员券后价>付费会员总标签价>总售价”
   * 非付费会员取的优先级“非付费会员券后价>非付费会员总标签价>总售价”
   */
  // 原始的折扣列表
  const originCouponList =
    bundleCouponPrices.filter(d => d.is_vip_promotion == is_vip) || []
  // 优惠力度最大的置顶
  const maxCouponList =
    originCouponList.concat([]).sort((a, b) => {
      return (
        Number(b.coupon_discount_price?.amount) -
        Number(a.coupon_discount_price?.amount)
      )
    }) || []
  // 有可能为空
  const _couponPrice = maxCouponList?.[0]
  // 展示价
  const _displayPrice = bundleDisplayPrices.find(
    d => d.isVipPromotion == is_vip
  )

  if (_couponPrice && !isHitComplianceMode) { // 到手价（券后价）
    // 券数据格式化
    res.couponList = originCouponList?.map(d => {
      const _fmt = souceFormat({ ...d, rule: [d.rule] })
      return getFormatCouponItemData({
        item: _fmt,
        language,
        discountAmountWithSymbol: d.coupon_discount_value?.amountWithSymbol
      })
    })

    // todo，最优惠
    res.optimalCoupon = maxCouponList[0]
    res.amountWithSymbol = _couponPrice?.after_coupon_price?.amountWithSymbol
    res.discount = _couponPrice.coupon_discount
    res.discountAmountWithSymbol =
      _couponPrice.coupon_discount_price.amountWithSymbol
    // 如果用的是“券后价（别管是付费会员还是非付费会员）对应的价差”，这里的价格先取展示价价差，再取售价差价
    res.activityAmountWithSymbol =
      (!isAmountEmpty(
        _displayPrice?.bundleShowDiscountPriceWithoutAb?.amount
      ) &&
        _displayPrice?.bundleShowDiscountPriceWithoutAb?.amountWithSymbol) ||
      (!isAmountEmpty(bundleDiscountPriceWithoutAb?.amount) &&
        bundleDiscountPriceWithoutAb?.amountWithSymbol)

    // 将所有券的结束时间排序，取最早的一个，格式为秒级别时间戳，示例：1709439490
    const couponEndDateList = res.couponList
      .map(d => d.end_date)
      .sort((a, b) => a - b)
      .filter(d => d)
    res.couponEndDate = couponEndDateList[0]
  } else if (_displayPrice) {
    res.amountWithSymbol = _displayPrice?.bundleShowPrice?.amountWithSymbol
    res.discount = _displayPrice.unit_discount
    res.discountAmountWithSymbol =
      (!isAmountEmpty(_displayPrice.bundleShowDiscountPrice?.amount) &&
        _displayPrice.bundleShowDiscountPrice?.amountWithSymbol) ||
      ''
    // 如果用的是“标签价（别管是付费会员还是非付费会员）对应的价差”，这里的价格就直接用展示价价差
    res.activityAmountWithSymbol =
      (!isAmountEmpty(_displayPrice.bundleShowDiscountPriceWithoutAb?.amount) &&
        _displayPrice.bundleShowDiscountPriceWithoutAb.amountWithSymbol) ||
      ''
  } else {
    res.amountWithSymbol = bundleSalePrice.amountWithSymbol
    res.discount = unit_discount
    res.discountAmountWithSymbol =
      !isAmountEmpty(bundleDiscountPrice?.amount) &&
      bundleDiscountPrice.amountWithSymbol

    // 如果用的是“售价对应的价差”，这里的价格就直接用售价价差
    res.activityAmountWithSymbol =
      !isAmountEmpty(bundleDiscountPriceWithoutAb?.amount) &&
      bundleDiscountPriceWithoutAb.amountWithSymbol
  }

  // AB价价差中台单独传递过来，在前端展示在劵列表的第一位，这里单独处理，注意它和劵后价的差别。
  if (!isAmountEmpty(bundleAbDiscountPrice?.amount)) {
    const itemForABPrice = {
      _couponName: _getAbCouponInfo(
        bundleAbDiscountPrice.amountWithSymbol,
        language
      ),
      _discountAmountWithSymbol: bundleAbDiscountPrice.amountWithSymbol
    }
    res.couponList.unshift(itemForABPrice)
  }
  return res
}


/**
 * 格式化券信息
 */
export const getFormatCouponItemData = (options = {}) => {
  const {
    item,
    language = {},
    abtEstimated = false,
    discountAmountWithSymbol = ''
  } = options

  const {
    coupon_rule,
    apply_for,
    real_type_id,
    shipping_discount_type,
    coupon_type_id
  } = item
  // todo 只取第一条
  const couponRule = coupon_rule?.[0] || {}
  const getDiscountText = () => {
    // 百分比格式化
    const percentFormat = num => {
      const newNum = (num * 100) / 100
      const percentNum = newNum + '%'
      return percentNum
    }

    const getTitleLanguage = () => {
      if (real_type_id == '53') {
        return '{0}'
      } else {
        return htmlDecode({
          text: couponRule.coupon_gift_id
            ? language.SHEIN_KEY_PC_18189
            : language.SHEIN_KEY_PC_15957
        })
      }
    }
    // ab价
    if (apply_for == 101) {
      return _getAbCouponInfo(
        couponRule.value_amount.amountWithSymbol,
        language
      )
    } else {
      if (
        (real_type_id == '53' || real_type_id == '55') &&
        shipping_discount_type == 0
      ) {
        return language.SHEIN_KEY_PC_15911
      } else {
        if (['5', '6'].includes(apply_for)) {
          return language.SHEIN_KEY_PC_15911
        } else if (coupon_type_id == '1') {
          return `- ${couponRule.value.amountWithSymbol}`
        } else {
          return template(
            percentFormat(couponRule.value.amount),
            getTitleLanguage(couponRule)
          )
        }
      }
    }
  }
  const discountText = getDiscountText()
  // 优惠券门槛信息
  let couponSubTitle = ''
  const { min, max } = couponRule ?? {}
  if (max && max.amountWithSymbol) {
    couponSubTitle = template(
      min.amountWithSymbol,
      max.amountWithSymbol,
      htmlDecode({ text: language.SHEIN_KEY_PC_19368 })
    )
  } else if (min?.amount > 0) {
    couponSubTitle = template(
      min.amountWithSymbol,
      htmlDecode({
        text: language[
          abtEstimated ? 'SHEIN_KEY_PC_26542' : 'SHEIN_KEY_PC_15958'
        ]
      })
    )
  } else if (Number(min?.amount) === 0 && apply_for !== 101) {
    couponSubTitle = language.SHEIN_KEY_PC_27865 || ''
  }
  return {
    ...item,
    _couponName: `${discountText} ${couponSubTitle}`,
    _discountAmountWithSymbol: discountAmountWithSymbol
  }
}

export function isAmountEmpty(amount) {
  return !amount || amount === '0.00' || Number(amount) === 0
}

/**
 * 计算主商品的到手价 
*/
export const calMainEstimatedInfo = (options = {}) => {
  const { getEstimatedInfo, language, retailAmountWithSymbol, activityAmountWithSymbol } = options  
  const hasEstimatedPrice = getEstimatedInfo?.value?.amountWithSymbol
  try {
    const {
      value = {},
      discountPercentForBelt,
      discountValueForBelt,
      optimalCoupon,
      optimalCouponList = []
    } = getEstimatedInfo

    const couponList = []
    optimalCouponList.forEach(d => {
      if (!d.rule || typeof d.rule !== 'object') return
      const rule = Array.isArray(d.rule) && d.rule || [d.rule]
      const item = souceFormat({
        ...d,
        rule
      })

      const _isAb = d.apply_for == 101
      const discountAmountWithSymbol = _isAb ? rule[0]?.value_amount?.amountWithSymbol : d?.coupon_discount_value?.amountWithSymbol

      const cp = getFormatCouponItemData({
        item,
        language,
        abtEstimated: true,
        discountAmountWithSymbol,
      })
      if (!cp) return
      couponList.push(cp)
    })

    const _optimalCoupon = Array.isArray(optimalCoupon)
      && optimalCoupon[0]
      || optimalCoupon || {}

    // 101没有券编码
    const _isAb = _optimalCoupon?.apply_for == 101
    const optimalCouponFmt = _isAb
      ? couponList[0]
      : couponList?.find(item => item.coupon === _optimalCoupon?.coupon_code)

    // 取最快过期的优惠券倒计时
    const couponEndDateList = couponList
      .map(d => d.end_date)
      .sort((a, b) => a - b)
      .filter(d => d)

    const couponEndDate = couponEndDateList[0] || 0

    // 百分百字符串转为数字
    const discount =
      (discountPercentForBelt && discountPercentForBelt.match(/^(\d+(?:\.\d+)?)%/)?.[1]) || 0
    return {
      _hasEstimatedPrice: hasEstimatedPrice,
      _isMainGoodsPrice: true,
      // amount: value.amount,
      amountWithSymbol: value.amountWithSymbol,
      /**
       * 折扣率
       */
      discount,
      /**
       * 价差
       */
      // discountAmount: discountValue?.amount || 0,
      // discountAmountWithSymbol: discountValue?.amountWithSymbol || '',
      // 使用最终优惠价 todo @seb
      discountAmountWithSymbol: discountValueForBelt?.amountWithSymbol || '',
      /**
       * 原价
       */
      // retailAmout: retailPrice?.amount || 0,
      // retailAmountWithSymbol: retailPrice?.amountWithSymbol || '',
      retailAmountWithSymbol,
      /**
       * 最优惠券信息
       */
      optimalCoupon: optimalCouponFmt,
      // couponList: coupons,
      couponList,

      /**
       * 展示的Activity价差
       */
      activityAmountWithSymbol,

      /**
       * 倒计时结束时间
       */
      couponEndDate
    }
  } catch (error) {
    return {
      amount: 0,
      amountWithSymbol: 0,
      discount: 0,
      retailAmout: 0,
      retailAmountWithSymbol: 0,
      discountAmountWithSymbol: '',
      optimalCoupon: null,
      couponList: []
    }
  }
}

/**
 * 获取一行一图推荐原子参数
 * @param {*} options
 * @returns
 */
export const getOneCardAtomRecommendParams = async (options = {}) => {
  const {
    itemConfig = {},
    subPageKey = '',
    recPosKey,
    extednsConfig = {}
  } = options
  const abtRules = await getUserAbtData()
  const recReqParams = {}
  if (recPosKey) {
    recReqParams.location = recPosKey
    recReqParams.jsonRuleId = JSON.stringify(abtRules?.[recPosKey]?.param || {})
  }

  const requestMenuList = [
    'mallInfo',
    'prices',
    'stock',
    'vipDiscountPrices', // 免费会员
    'baseInfo',
    'locateLabels', // 色块数据
    'saleAttrList', // 销售属性
    'relatedProducts', // 色块
    'mainSaleAttr', // 主销售属性
    'cccTspBadges',
    'plusSize',
    'seriesAndBrand',
    'rankingList', // 榜单
    'beltLabel', // 腰带原子
    'userBehaviorLabel', // 用户行为标签
    'userCommentKeywordsLabel', // 评论关键词标签
    'sellingPoints', // 人工卖点
    'sheinClubPromotionInfo', // 付费会员标签/付费会员价
    'newFlashPromotion', // 新用户价
    'quickShip', // 快速发货
    'promotion', // 服务类标签-【buy more】 filed=promotion
    'serviceLabel', // 服务类标签-【通用角标类型-优选卖家】
    'labelsBeforeGoodsName', // new 角标
    'estimatedPrice', // 到手价价格
    'priceDiscountDesc', // 历史低价、保留款跟价款标签
    'abPrice', // ab价原子
    'cornerPropertyUpsell',
    'estimatedPrice', // 到手价原子
    'horseRaceLabel', // 赛马原子
    ...(extednsConfig?.hideSalesLabel ? [] : ['salesLabel']) // 销量标签
  ]

  const atomFields = {
    ...getAtomicRequestFileds(itemConfig, requestMenuList, true),
    realTimeTspLabels: { label_cloud: ['600020741'], machine_label: ['3120'] }, // 全端泛列表场景支持替换营销图主图展示
    detailImage: true, // 全端泛列表场景支持替换营销图主图展示
    ...(await getDetailRecommendAtomFields() || {}),
  }
  const atomParams =
    await getDetailRecommendCardV3ReqAbtInfoCCCParams({
      newProductCard: true,
      isPaidUser: itemConfig._isPaidUser
    })

  return {
    ...atomParams,
    atomFields,
    atomScene: {
      pageKey: 'page_goods_detail_rec',
      subPageKey
    }
  }
}

/**
 * 根据外部一行三推荐数据获取弹窗一行一图数据
 */
const ROWS_KEY_MAP = {
  1: ['other_recommend_1pic', 'ONE_IN_A_ROW'],
  3: ['multiImageCommon', undefined]
}
export const getComboBuyPopupProductListByGoods = async (options = {}) => {
  const { goods, itemConfig, extednsConfig = {}, rows = 1 } = options

  const [subPageKey, sceneKey] = ROWS_KEY_MAP[rows] || []

  let _hasSkuCode = false
  // 清理数据
  const goodsReq = goods.map(d => {

    // 组合购/新搭配选中sku场景需要查询sku纬度价格
    const sku_code = d?._cartInfo?.skuCode || undefined

    if (!_hasSkuCode && sku_code) _hasSkuCode = true

    return {
      goods_id: d.goods_id,
      cat_id: d.cat_id,
      mall_code: d.mall_code,
      goods_sn: d.goods_sn,
      sku_code
    }
  })

  const { mallInfoForSite = [] } = await getSiteMallInfo()
  const { atomFields: fields, abtInfoV3: abtInfo, atomCccParams: cccParams, ...atomParams } = await getOneCardAtomRecommendParams({
    itemConfig,
    extednsConfig,
    subPageKey,
  })

  // sku价格原子
  if (_hasSkuCode && !fields?.skuPrices) {
    fields.skuPrices = true
  }

  const opt = {
    fields,
    abtInfo,
    ...atomParams,
    cccParams,
    pageKey: 'page_goods_detail_rec',
    subPageKey,
    sceneKey,
    goods: goodsReq,
    mallInfo: mallInfoForSite,
  }
  await itemSer.getAtomicInfoNew(opt)
  // 新商品卡片的场景
  await getDetailRecPreTreat({
    products: goodsReq,
    itemConfig
  })
  return goodsReq
}

/**
 * 通过skuCode更新item
 */
export const setCommonCartProductSkuInfo = (skuCode, item = {}, soldOutText) => {
  if (!skuCode) return
  const { sale_attr_list = {}, _cartInfo = {} } = item
  const { sku_list = [] } = sale_attr_list
  const _sku = sku_list.find(d => d.sku_code === skuCode)
  if (!_sku) return
  const { sku_sale_attr = [] } = _sku
  // 无销售属性不存在名称
  let skuName = ''
  // 快车弹窗回显用
  const selectedAttrIndex = {}
  if (!_cartInfo.isNotSku && sku_sale_attr.length) {
    const _name = []
    sku_sale_attr.forEach(d => {
      selectedAttrIndex[d.attr_id] = { attr_value_id: d.attr_value_id }
      const name = d.attr_value_name?.trim?.()
      if (!name) return
      _name.push(name)
    })
    if (_name.length) skuName = _name.join('/')
  }

  const isSkuSoldOut = isSkuCodeCanSale(skuCode, item) !== true
  if (isSkuSoldOut && (!_cartInfo.isSoldOut || _cartInfo.isOnlySkuSoldOut)) {
    item._cartInfo.isSoldOut = item._cartInfo.isOnlySkuSoldOut = true
    item.is_on_sale = '0'
    if (item.pretreatInfo) {
      item.pretreatInfo.saleout = {
        show: true,
        message: soldOutText
      }
    }

    if (item._cartInfo.selectStatus) {
      item._cartInfo.selectStatus = false
    }
  } else {
    // 如果曾经被设置为售罄
    if (item._cartInfo.isOnlySkuSoldOut) {
      item.is_on_sale = '1'
      item.pretreatInfo.saleout = {
        show: false,
        message: ''
      }
      item._cartInfo.isSoldOut = item._cartInfo.isOnlySkuSoldOut = false
    }
  }
  item._cartInfo.skuCode = skuCode
  item._cartInfo.skuName = skuName
  item._cartInfo.selectedAttrIndex = selectedAttrIndex
  return item
}

export const isSkuCodeCanSale = (skuCode, item = {}) => {
  const { mall_code, sale_attr_list = {} } = item
  const skuInfo = sale_attr_list.sku_list.find(d => d.sku_code === skuCode)
  if (!skuInfo) return
  return skuInfo.mall_stock.some(d => d.mall_code == mall_code && d.stock > 0)
}

/**
 * 首次打开弹窗时自动选择sku
 */
export const setProductListAutoSkuInfo = (
  list = [],
  ignoreMainGoods = false,
  soldOutText
) => {
  if (!list.length) return []
  list.forEach(d => {
    if (ignoreMainGoods && d._cartInfo.isMainGoods) return
    // skc维度售罄
    if (d._cartInfo.isSoldOut) return
    const skuCode = getOnlyStockSkuCode(d)
    if (!skuCode) return
    setCommonCartProductSkuInfo(skuCode, d, soldOutText)
  })
  return list
}

/**
 * 选中只有单sku或者只有单sku有库存的商品
 */
const getOnlyStockSkuCode = (item = {}) => {
  const { sale_attr_list = {}, mall_code, _cartInfo = {} } = item
  // 多个次级销售属性不处理 todo
  const { sku_list = [] } = sale_attr_list
  // 无销售属性
  if (_cartInfo.isNotSku) {
    // 无销售属性，从sku_list第一条取数据
    const _skuItem = sku_list[0]
    if (!_skuItem) return
    const _mallStock = _skuItem.mall_stock?.find?.(
      d => d.mall_code === mall_code
    )
    if (_mallStock?.stock > 0) {
      return _skuItem.sku_code
    } else {
      return
    }
  } else {
    const canSaleMallSkuList = []
    for (let i = 0; i < sku_list.length; i++) {
      const d = sku_list[i]
      const _mallStock = d.mall_stock?.find(d => d.mall_code === mall_code)
      if (_mallStock?.stock > 0) {
        canSaleMallSkuList.push(d)
      }
      // 超过两个可售sku，不处理
      if (canSaleMallSkuList.length > 1) return
    }

    // 必须仅剩一个可售
    if (canSaleMallSkuList.length !== 1) return
    return canSaleMallSkuList[0].sku_code
  }
}

/**
 * 批量加车后领券
 */
export async function autoGetCouponAtBatchAdd(couponList, isAddSuc, callGetCouponTip) {
  try {
    if (!isLogin()) return
    const couponCodes = couponList?.filter?.(item => item.coupon_status != 1).map(item => item.coupon) || []
    if (!couponCodes.length) return
    const res = await schttp({
      method: 'POST',
      url: '/api/productInfo/bindCoupon/post',
      data: {
        coupon_codes: couponCodes
      }
    })
    const failure =
      !res ||
      res.code != 0 ||
      !res.info?.successList?.find?.(item => couponCodes.includes(item.couponCode))
    if (failure) {
      return false
    } else {
      isAddSuc && callGetCouponTip && callGetCouponTip()
      return true
    }
  } catch(e) {
    return false
  }
}

/**
 * 检查当前商品列表是否可以加入购物车，抛出异常项
 */
export const checkCanAddToCart = (options = {}) => {
  const { list = [] } = options
  const res = {
    ok: false,
    isSoldOut: false,
    isNoSku: false,
    errList: []
  }

  list.forEach(d => {
    if (!d._cartInfo.selectStatus) return

    /**
     * 未选择sku不能加车
     */
    if (!d._cartInfo.skuCode) {
      res.isNoSku = true
      res.errList.push(d)
      return
    }

    /*
     * 售罄不能加车
     */
    if (d._cartInfo.isSoldOut) {
      res.isSoldOut = true
      res.errList.push(d)
      return
    }
  })

  res.ok = res.errList.length === 0
  return res
}

/**
 * 批量加车
 */
export const batchAddToCart = async (list = []) => {
  const product_list = list
    .map(d => ({
      mall_code: d.mall_code,
      sku_code: d._cartInfo.skuCode,
      quantity: 1
    }))
    .filter(d => d.sku_code)
  if (!product_list.length) return
  const data = {
    product_list
  }
  const result = await schttp({
    url: '/api/cart/batch_add_mall/create',
    method: 'POST',
    data
  })
  return result
}

/**
 * 单sku时直接加车
 */
export const oneSkuAddToBag = async (productItem = {}, language) => {
  const saleAttrList = productItem.sale_attr_list
  const skuInfo = saleAttrList?.sku_list?.[0]
  const data = {
    goods_id: productItem?.goods_id,
    mall_code: productItem?.mall_code,
    sku_code: skuInfo?.sku_code || '',
    quantity: 1
  }
  const res = await schttp({
    url: '/api/cart/add_mall/create',
    method: 'POST',
    data
  })
  // 错误才做提示
  if(res?.code != 0){
    let errorCodeTips = ''
    const { code } = res || {}
    const ERROR_CODE = +code
    switch (ERROR_CODE) {
      case 300405: // 清仓限购
        {
          const limitCount = res.info?.limitCount || res.info?.resultLimit
          errorCodeTips = limitCount ? template(limitCount, language.SHEIN_KEY_PC_14594) : language.SHEIN_KEY_PC_17492
        }
        break
      case 200401: // 限时折扣限购
      case 500301:
        errorCodeTips = language.SHEIN_KEY_PC_14661
        break
      case 300402: // 缺货
        errorCodeTips = language.SHEIN_KEY_PC_15071
        break
      case 300407: // 购物车满了
        errorCodeTips = res.tips
        break
      case 500303: // 限购
        errorCodeTips = template(res.info?.limitCount || '', res.info?.resultLimit || 0, res.info?.remainNum || 0, res.tips)
        break
      case 500306: // 闪购限购
      case 500302:
      case 300417:
        errorCodeTips = template(res.info?.limitCount || '', res.info?.resultLimit || 0, res.info?.remainNum || 0, res.tips)
        break
      case 500304: //打折促销 单品限制
      case 500305: //打折促销 订单限制
        errorCodeTips = language.SHEIN_KEY_PC_17492
        break
      default :
        errorCodeTips = res.tips
    }
    SMessage({
      message: errorCodeTips,
      type: 'error',
      offset: 180,
    })
  }
  return res
}


/**
  加车一件商品就报一条
  图示是4件商品，就是4条
  1、前端校验出现错误时，上报加车失败（只上报前端校验失败情况）
  2、前端校验成功，待接口返回结果后，上报（可能成功，可能失败，都上报）
 */
export const handleExposeAddCart = (options = {}) => {
  const {
    from = 'togetherbuy',
    list = [],
    result,
    location = 'popup',
    result_reason,
    tspABTest = '',
    series_no = ''
  } = options
  const cookieId = getCookie('cookieId') || ''
  const click_id = `${cookieId}${Date.now()}`
  if (!list.length) {
    sendAddToCartEvent({
      from,
      result,
      item: {},
      click_id,
      location,
      result_reason,
      tspABTest,
      series_no
    })
  } else {
    list.forEach(d => {
      sendAddToCartEvent({
        from,
        result,
        item: d,
        click_id,
        location,
        result_reason,
        tspABTest,
        series_no
      })
    })
  }
}

const sendAddToCartEvent = ({
  from,
  result,
  item = {},
  click_id,
  location,
  result_reason,
  tspABTest = '',
  series_no = ''
}) => {
  const extraData = {
    code: 'goodsDetail',
    result,
    postData: {
      goods_id: item.goods_id || '',
      sku: item.goods_sn || '',
      spu: item.productRelationID || '',
      sku_code: item?._cartInfo?.skuCode || '',
      mallCode: item.mall_code || '',
      location,
      click_id,
      series_no,
      quickShip: item.quickship || ''
    },
    from,
    review_location: '',
    traceid: window.SaPageInfo?.page_param?.traceid,
    faultReason: result_reason
  }
  if(tspABTest) {
    extraData.tspABTest = tspABTest
  }
  daEventCenter.triggerNotice({
    daId: '1-7-1-1',
    extraData,
    target: item.target || ''
  })
}

/**
 * 移除skuCode选中状态
 */
export const setCommonCartProductSkuInfoEmpty = (item = {}) => {
  item._cartInfo.skuCode = item._cartInfo.skuName = ''
  item._cartInfo.selectedAttrIndex = {}
}

/**
 * 同步两个商品状态
 */
export function setSyncItemCartInfo(item, fromItem) {
  if (!item || !fromItem) return

  setCommonCartProductSkuInfoEmpty(item)
  const ousideSkuCode = fromItem._cartInfo?.skuCode
  if (ousideSkuCode) {
    setCommonCartProductSkuInfo(ousideSkuCode, item)
  }

  // 同步主商品状态
  item._cartInfo.isMainGoods = !!fromItem._cartInfo.isMainGoods

  item._cartInfo.selectStatus = false
  if (!fromItem._cartInfo.selectStatus) return
  if (item._cartInfo.isSoldOut) return
  // 如果选择了sku，但是sku不可售，不勾选（同步主商品会有这种情况）
  if (item._cartInfo.skuCode && !isSkuCodeCanSale(item._cartInfo.skuCode, item))
    return
  item._cartInfo.selectStatus = true
}

/**
 * 异步获取商品列表Spu售罄状态
 */
export async function setProductListSpuSoldOut(options = {}) {
  const { goods = [], rows = 3 } = options
  if (!goods.length) return

  const reqRelatedColor = []
  let reqRelatedColorIdMap = {}
  const checkItemList = []

  goods.forEach(item => {
    // 如果当前商品未售罄，则不会spu售罄
    if (!item._cartInfo.isSoldOut) return
    if (!item?.relatedColor?.length) return

    let _hasColor = false
    // 清理数据
    item.relatedColor.forEach(d => {
      // 当前商品不查询
      if (d.goods_id == item.goods_id) return
      _hasColor = true

      reqRelatedColor.push({
        goods_id: d.goods_id,
        cat_id: d.cat_id,
        mall_code: d.mall_code,
        goods_sn: d.goods_sn
      })

      reqRelatedColorIdMap[d.goods_id] = item.goods_id
    })

    if (_hasColor) checkItemList.push(item)
  })
  if (!reqRelatedColor.length) return

  const [subPageKey, sceneKey] = ROWS_KEY_MAP[rows] || []
  const { mallInfoForSite = [] } = await getSiteMallInfo()

  const fields = {
    stock: 1
  }

  const opt = {
    fields,
    cccParams: {},
    abtInfo: {},
    pageKey: 'page_goods_detail_rec',
    subPageKey,
    sceneKey,
    goods: reqRelatedColor,
    mallInfo: mallInfoForSite
  }

  await itemSer.getAtomicInfoNew(opt)


  const reqRelatedColorGoodMap = reqRelatedColor.reduce((map, d) => {
    map[d.goods_id] = d
    return map
  })

  checkItemList.forEach(item => {
    const checkColorList = []
    const checkColorListHasStock = []
    item.relatedColor.forEach(d => {
      const stockGood = reqRelatedColorGoodMap[d.goods_id]
      // 当前商品不查询
      if (!stockGood) return
      checkColorList.push(stockGood)

      const isSoldOut = d.stock == 0 || d.is_on_sale == 0
      if (!isSoldOut) checkColorListHasStock.push(d)
    })

    // test
    // if (item._cartInfo.isMainGoods) item._cartInfo.isSpuSoldOut = true

    if (checkColorList.length !== checkColorListHasStock.length) {
      item._cartInfo.isSpuSoldOut = true
    }
  })
}

/**
 * 设置所有商品为非选中
 */
export function setAllProductSelectEmpty(list = []) {
  list.forEach(d => {
    if (!d?._cartInfo?.selectStatus) return
    d._cartInfo.selectStatus = false
  })
}

export const getFormatBatchAddErrorResult = (data = {}, language = {}) => {
  const { code, info = {}, tips } = data

  let _errorTips = ''
  if (code == 0) {
    /**
     * 加车成功
     */
    return data
  } else {
    const limitCount = info?.limitCount || ''
    if (code == '300402') {
      _errorTips = language.value.SHEIN_KEY_PC_14617 || 'Out of Stock'
    } else if (['200401', '500301'].includes(code)) {
      _errorTips = language.value.SHEIN_KEY_PC_14661 || 'limit quantity'
    } else if (['500306', '500302', '300417', '500303'].includes(code)) {
      _errorTips = template(
        limitCount,
        info?.resultLimit || 0,
        info?.remainNum || 0,
        tips
      )
    } else if (code == '300407') {
      _errorTips = tips
    } else {
      _errorTips = language.value.SHEIN_KEY_PC_14605 || 'error'
    }
    return {
      _errorTips
    }
  }
}

/**
 * 批量加车前请求售罄状态
 */
export const setComboBuyProductListSoldStatus = async (options = {}) => {
  const { itemConfig, goods } = options
  if (!goods.length) return
  // 每次点击加车更新库存数据
  const popupProductMap = getFormatComboBuyProductList(
    await getComboBuyPopupProductListByGoods({
      goods,
      itemConfig
    })
  ).reduce((map, d) => {
    map[d.goods_id] = d
    return map
  }, {})

  const selectSoldOut = []
  const diffSoldOutList = []
  goods.forEach((d, i) => {
    const newItem = popupProductMap[d.goods_id]
    const _isSelect = d._cartInfo.selectStatus
    const _isSkcSoldOut = d._cartInfo.isSoldOut && !d._cartInfo.isOnlySkuSoldOut
    const _hasDiffSoldOut = _isSkcSoldOut !== newItem._cartInfo.isSoldOut

    if (_hasDiffSoldOut) {
      diffSoldOutList.push(newItem)
      if (d._cartInfo.isMainGoods) {
        newItem._cartInfo.isMainGoods = true
      }
      goods.splice(i, 1, newItem)
      _isSelect && selectSoldOut.push(newItem)
      return
    }

    // 选择状态检查sku是否售罄
    if (
      _isSelect &&
      d._cartInfo.skuCode &&
      !isSkuCodeCanSale(d._cartInfo.skuCode, newItem)
    ) {
      if (d._cartInfo.isMainGoods) {
        newItem._cartInfo.isMainGoods = true
      }
      goods.splice(i, 1, newItem)
      selectSoldOut.push(newItem)
      return
    }
  })

  return { selectSoldOut, diffSoldOutList }
}

/**
 * 埋点相关
 */
export const getProductListExposeAnalysisData = ({ options = {} }) => {
  const { click_type, isPopup, activity_from, reportCode, posKeys, series_no } = options
  const saAbtTest = abtUserAnalysis({ posKeys })?.sa || '-'
  const data = {
    abtest: saAbtTest,
    activity_from,
    style: isPopup ? 'popup' : 'detail',
    tab_list: '-',
    location: isPopup ? 'popup' : 'page',
    click_type
  }
  if(series_no) {
    data.series_no = series_no
  }
  return {
    id: '1-8-10-2',
    code: reportCode,
    data
  }
}


/**
 * 统一构造ab价券信息
 */
function _getAbCouponInfo(amountWithSymbol, language = {}){
  return template(amountWithSymbol, language.SHEIN_KEY_PC_27312)
}



/**
 * 非主商品不展示到手价
 */
export function getNotEstimatedProductCardConfig(itemConfig = {}, { showEstimatedPrice = false } = {}) {
  if (showEstimatedPrice) return itemConfig
  return getNotEstimatedPriceConfig(itemConfig)
}
